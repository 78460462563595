import constants from '@/constants'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'

const { ORDER_PRODUCT_SHIPMENT_STATUS } = c
const { ORDER_STATUS } = constants

export const enableShipmentFeature = orderData => {
  if (orderData.status === ORDER_STATUS.CONFIRMED) {
    const readyToShipProducts = orderData.products.filter(
      product => product.status === ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL,
    )
    if (readyToShipProducts.length) {
      orderData.isEnableShipmentFeature = true
      orderData.readyToShipProductsCount = readyToShipProducts.length
    }
    orderData.products.forEach(product => {
      // Securing the raw items for create shipment
      product.itemsForShipment = product.items
    })
  }
}

export const parseOrderVariant = status => {
  switch (status) {
    case ORDER_STATUS.NEW:
      return 'info'
    case ORDER_STATUS.CANCELLED:
      return 'warning'
    case ORDER_STATUS.REJECTED:
      return 'danger'
    case ORDER_STATUS.ACCEPTED:
      return 'success'
    default:
      return null
  }
}

export const parseOrderIcon = status => {
  switch (status) {
    case ORDER_STATUS.NEW:
      return 'DollarSignIcon'
    case ORDER_STATUS.CANCELLED:
      return 'RotateCcwIcon'
    case ORDER_STATUS.REJECTED:
      return 'XCircleIcon'
    case ORDER_STATUS.ACCEPTED:
      return 'StarIcon'
    default:
      return 'InfoIcon'
  }
}

export const getProductItem = (items, modifiedItems = []) => {
  const currentItems = []
  items.forEach(item => {
    const productItem = JSON.parse(JSON.stringify(item))
    const index = modifiedItems.findIndex(
      modifiedItem => modifiedItem.skuId === item.skuId,
    )
    if (index >= 0) {
      productItem.value = modifiedItems[index].value
    }
    currentItems.push(productItem)
  })
  return currentItems
}

export const ORDER_SEARCH_FIELD = {
  RETAILER_NAME: 'retailerName',
  BRAND_NAME: 'brandName',
  COLLECTION_NAME: 'collectionName',
}

export const getSearchByEndpoint = (isBrand = false) => (isBrand ? ORDER_SEARCH_FIELD.RETAILER_NAME : ORDER_SEARCH_FIELD.BRAND_NAME)

export const formatProducts = products => {
  const payload = []
  let cancelledProductsCount = 0
  products.forEach(prod => {
    if (prod.isCarton) {
      payload.push({
        productId: prod.productId,
        quantity: prod.quantity,
      })
    } else {
      const itemsList = []
      prod.variants?.forEach(item => {
        Object.entries(item).forEach(([, v]) => {
          if (v.value >= 0) {
            itemsList.push({
              skuId: v.skuId,
              value: v.value,
            })
          }
        })
      })
      payload.push({
        productId: prod.productId,
        items: itemsList,
      })
    }
    if (prod.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL) {
      cancelledProductsCount++
    }
  })

  return { payload, cancelledProductsCount }
}

export const flattenItems = data => data.flatMap(product => {
    if (product.items && product.items.length > 0) {
      return product.items.map(item => ({
        productId: product.productId,
        skuId: item.skuId,
        value: item.value,
      }))
    } else if (product.quantity !== undefined) {
      return [
        {
          productId: product.productId,
          skuId: null,
          value: product.quantity,
        },
      ]
    } else {
      return []
    }
  })
